// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fighters-cook-js": () => import("./../../../src/pages/fighters/cook.js" /* webpackChunkName: "component---src-pages-fighters-cook-js" */),
  "component---src-pages-fighters-dev-js": () => import("./../../../src/pages/fighters/dev.js" /* webpackChunkName: "component---src-pages-fighters-dev-js" */),
  "component---src-pages-fighters-farmer-js": () => import("./../../../src/pages/fighters/farmer.js" /* webpackChunkName: "component---src-pages-fighters-farmer-js" */),
  "component---src-pages-fighters-musician-js": () => import("./../../../src/pages/fighters/musician.js" /* webpackChunkName: "component---src-pages-fighters-musician-js" */),
  "component---src-pages-fighters-photographer-js": () => import("./../../../src/pages/fighters/photographer.js" /* webpackChunkName: "component---src-pages-fighters-photographer-js" */),
  "component---src-pages-fighters-thoughts-js": () => import("./../../../src/pages/fighters/thoughts.js" /* webpackChunkName: "component---src-pages-fighters-thoughts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

